import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import propTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

import Button from "components/Button";
// import configs from './configs'
import "@goalabs.id/react-shared-components/dist/components/Button/index.css";
import Spinner from "../Spinner";
import Icon from "../Icon";
import Input from "../Form/Input";
import override from "../../helpers/objects/override";

/**
 * UI component to select one of the solution from the list
 */
const defaultConfigs = {
  icon: {
    display: true,
  },
  list: {
    default: "Choose",
    empty: "No option available",
    closeOnSelect: true,
    setCurrentOnSelect: false,
    position: "left",
  },
  search: {
    display: false,
    placeholder: "Type to filter",
    loading: false,
    onChange: () => {},
    delay: 500,
  },
};

const Dropdown = (props) => {
  const [current, setCurrent] = useState(
    props.list
      ? [...props.list].find((item) => item.value === props.value)?.display
      : null
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [toggle, setToggle] = useState(false);
  const className = [
    "whitespace-nowrap flex",
    toggle ? "bg-neutral-300 hover:bg-neutral-300" : "",
    props.className || "",
  ];

  const ref = useRef(null);

  const configs = override(defaultConfigs, props.configs);

  const fnChange = useCallback(
    (event) => {
      if (configs?.search?.display) {
        setTimeout(() => {
          configs?.search?.onChange?.(event) || void 0;
        }, configs?.search?.delay || 500);
      } else {
        configs?.search?.onChange?.(event) || void 0;
      }
    },
    [configs]
  );

  /*
      must wait animation in/out finished
   */
  const fnToggle = useCallback(() => {
    if (!props.disabled) {
      setToggle((prev) => !prev);
      fnChange({
        target: {
          value: "",
        },
      });
    }
  }, [props.disabled, fnChange]);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref && ref.current && !ref.current.contains(event.target) && toggle) {
        fnToggle();
      }
    },
    [fnToggle, toggle]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if ([40, 38, 13, 27].includes(Number(event.keyCode)))
        event.preventDefault();

      switch (Number(event.keyCode)) {
        case 40: //up
          if (selectedIndex < props.list.length - 1)
            setSelectedIndex((prev) => +prev + 1);
          else setSelectedIndex(0);
          break;
        case 38: //down
          if (selectedIndex === 0) setSelectedIndex(props.list.length - 1);
          else setSelectedIndex((prev) => +prev - 1);
          break;

        case 13: //enter
          if (props.list[selectedIndex]) {
            if (configs.list.setCurrentOnSelect)
              setCurrent(props.list[selectedIndex].display);

            setToggle(false);
            props.list[selectedIndex].onSelect();
          }
          break;

        case 27: //escape
          setToggle(false);
          break;

        default:
          return false;
      }
    },
    [
      props.list,
      setSelectedIndex,
      selectedIndex,
      setToggle,
      configs.list.setCurrentOnSelect,
    ]
  );

  useLayoutEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useLayoutEffect(() => {
    if (toggle) document.getElementById(`q-${props.name}`)?.focus();
  }, [toggle, props.name]);

  useLayoutEffect(() => {
    const refWrapper = ref.current;
    refWrapper.addEventListener("keydown", handleKeyDown);

    return () => {
      refWrapper.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    setCurrent(props.list.find((item) => item.value === props.value)?.display);
  }, [props.value, props.list]);

  return (
    <div className="relative" ref={ref}>
      <Button
        {...props}
        state="secondary"
        className={className.join(" ")}
        onClick={fnToggle}
      >
        <button>
          {current || configs?.list?.default || "Choose"}
          {configs?.icon?.display && (
            <span
              className={[
                "ml-2 transform flex items-center transition-transform duration-200",
                toggle ? "rotate-180" : "rotate-0",
              ].join(" ")}
            >
              <Icon size="16" item="arrow-ios-downward"></Icon>
            </span>
          )}
        </button>
      </Button>
      <CSSTransition
        appear
        in={toggle}
        timeout={250}
        classNames="overlay"
        unmountOnExit
      >
        <div
          className={[
            "overlay absolute top-100 bg-white shadow-dropdown z-10 rounded mt-1 min-w-max",
            configs?.list?.position === "right" ? "right-0" : "",
          ].join(" ")}
        >
          {configs.search.display && (
            <div className="px-4 pt-4 pb-2 relative">
              <Input
                id={`q-${props.name}`}
                name={`q-${props.name}`}
                required
                onChange={fnChange}
                defaultValue=""
                placeholder={configs.search.placeholder || "Type to filter"}
              />
              {configs.search.loading && (
                <span className="absolute right-0 top-0 flex items-center justify-center w-full h-full">
                  <Spinner />
                </span>
              )}
            </div>
          )}

          {!props?.list || props.list.length === 0 ? (
            <div className="px-6 pt-1 pb-3 text-sm text-neutral-600">
              {configs?.list?.empty || "No Item Found"}
            </div>
          ) : (
            <ul className="py-2">
              {props.list.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    className={[
                      "px-6 py-1 cursor-pointer hover:bg-neutral-200 flex items-center",
                      selectedIndex === index ? "bg-neutral-300" : "",
                      props.value === item.value ? "bg-green-200" : "",
                    ].join(" ")}
                    onClick={() => {
                      item.onSelect();

                      if (configs.list.setCurrentOnSelect)
                        setCurrent(item.display);
                      if (configs.list.closeOnSelect) fnToggle();
                    }}
                  >
                    {item.display}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

Dropdown.defaultProps = {
  className: "",
  size: "md",
  configs: defaultConfigs,
};

Dropdown.propTypes = {
  /**
   * 🎚 Define the Dropdown size
   */
  size: propTypes.oneOf(["sm", "md", "lg"]),

  /**
   * ✨ Custom css class
   */
  className: propTypes.string,

  /**
   * ✨ Accept value to select the list on first load
   */
  value: propTypes.string,

  /**
   * ✅ Determine the Dropdown is disabled or not
   */
  disabled: propTypes.bool,

  /**
   * ✅ Determine the Dropdown is in loading state
   */
  loading: propTypes.bool,

  /**
   * Accept an array to map all the list of options
   */
  list: propTypes.array.isRequired,

  /**
   * Accept an configs object to make Dropdown match with the needs
   */
  configs: propTypes.object,
};

export default Dropdown;
