import axios from "axios";
import errorHandler from "./errorHandler";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});

export const setAuthorizationHeader = (token = null) => {
  if (token) {
    instance.defaults.headers.common.authorization = token;
  } else {
    delete instance.defaults.headers.common.authorization;
  }
};

instance.interceptors.response.use((response) => response, errorHandler);

export const CancelToken = axios.CancelToken;

instance.defaults.timeout = 999999;

export default instance;
