import React, { useCallback, useEffect, useState } from "react";

import Dropdown from "components/Dropdown";
import Input from "components/Form/Input";
import Button from "components/Button/";

import useForm from "helpers/hooks/useForm";

import { createTimelines, getTimelines } from "configs/api/leads";

import "helpers/format/date";

export default function ModalUpdateLeads({ item }) {
  const [timelines, setTimelines] = useState([]);

  const [payload, setPayload] = useForm({
    description: "",
    status: item.status,
  });

  const status = {
    LEADS_COLD: {
      id: "LEADS_COLD",
      label: "Leads Cold",
      className: "border-neutral-400",
    },
    LEADS_WARM: {
      id: "LEADS_WARM",
      label: "Leads Warm",
      className: "border-orange-400",
    },
    LEADS_HOT: {
      id: "LEADS_HOT",
      label: "Leads Hot",
      className: "border-red-400",
    },
    WAITING_SIGN_MOU: {
      id: "WAITING_SIGN_MOU",
      label: "Waiting Sign MOU",
      className: "border-purple-400",
    },
    WAITING_ENTRY_DATA: {
      id: "WAITING_ENTRY_DATA",
      label: "Waiting Entry Data",
      className: "border-yellow-400",
    },
    WAITING_IMPORT: {
      id: "WAITING_IMPORT",
      label: "Waiting Import",
      className: "border-blue-400",
    },
    TRAINING: {
      id: "TRAINING",
      label: "Training",
      className: "border-green-400",
    },
    REJECTION: {
      id: "REJECTION",
      label: "Rejection",
      className: "border-red-900",
    },
    DEMO_SCHEDULE: {
      id: "DEMO_SCHEDULE",
      label: "Demo Schedule",
      className: "border-green-800",
    },
  };
  // penolakan, janji demo
  const fetchTimelines = useCallback(async () => {
    const newTimelines = await getTimelines(item.id);
    setTimelines(newTimelines.data.payload);
  }, [item.id]);

  useEffect(() => {
    fetchTimelines();
  }, [fetchTimelines]);

  async function fnSubmit() {
    try {
      if (item.id) {
        const res = await createTimelines(item.id, {
          ...payload,
        });
        if (res) {
          fetchTimelines();
          //   modal.hide();
        }
      }
    } catch (error) {}
  }
  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-5/12 lg:border-r border-slate-200">
        <div className="p-4 lg:p-10">
          <h6>Rubah Leads Status</h6>
          <div className="mt-4 w-4/12 flex flex-col">
            <label htmlFor="name">Status:</label>
            <Dropdown
              className="px-4"
              configs={{
                list: {
                  closeOnSelect: true,
                  default: "Status",
                  empty: "No option available",
                },
              }}
              list={Object.keys(status).map((item) => {
                return {
                  display: <span>{status[item].label}</span>,
                  id: status[item].id,
                  name: status[item].id,
                  onSelect: () => {
                    setPayload({
                      target: {
                        name: "status",
                        value: status[item].id,
                      },
                    });
                  },
                  value: status[item].id,
                };
              })}
              value={payload.status}
              name="status"
            />
          </div>
          <div className="mt-4 w-full flex flex-col">
            <label htmlFor="description">Description:</label>
            <Input
              className="w-full"
              value={payload.description}
              id="description"
              name="description"
              onChange={setPayload}
              placeholder="Description"
            />
          </div>

          <Button state="primary" className="px-6 mt-4" onClick={fnSubmit}>
            <button>Simpan</button>
          </Button>
        </div>
      </div>
      <div className="w-full lg:w-5/12">
        <div className="p-10">
          {timelines.map((timeline) => {
            return (
              <div
                className={[
                  "border-l-4 mb-4 pl-6",
                  status[timeline.status].className,
                ].join(" ")}
                key={timeline.id}
              >
                <small>{timeline.createdAt.date()}</small>
                <h6>{status[timeline.status].label}</h6>
                <p>{timeline.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
