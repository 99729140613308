import React from 'react'
import propTypes from 'prop-types'

import {
   Input,
   Label,
} from '@goalabs.id/react-shared-components/dist/components/Form/'
// import configs from '../../configs/components/Radio'

/**
 * Form / Radio;
 * UI component present as a input type radio
 */
const Radio = (props) => {
   const id = `${Math.random(100)}-${props.id}`

   return (
      <Label
         htmlFor={id}
         style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
         }}
         className="select-none"
      >
         <Input
            disabled={props.disabled}
            readOnly={props.readOnly}
            id={id}
            name={props.name}
            defaultChecked={props.defaultChecked}
            checked={props.checked}
            type="radio"
            className="appearance-none focus:outline-none rounded-full transition-colors duration-200 h-4 w-4 mr-1 border border-neutral-400 bg-white checked:bg-yellow-500 disabled:bg-neutral-300"
            value={props.value}
         />
         {React.Children.only(props.children)}
      </Label>
   )
}

Radio.defaultProps = {}

Radio.propTypes = {
   /**
    * input and label id
    */
   id: propTypes.string.isRequired,

   /**
    * input name
    */
   name: propTypes.string.isRequired,

   /**
    * ✨ Custom css class
    */
   className: propTypes.string,

   /**
    * 🎨 Custom style
    */
   style: propTypes.object,

   /**
    * ✅ Define the input default checked status in first load
    */
   defaultChecked: propTypes.bool,

   /**
    * ✅ Define the input checked status
    */
   checked: propTypes.bool,

   /**
    * 📝 Define the input default value
    */
   defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number]),

   /**
    * 📝 Define the input value
    */
   value: propTypes.oneOfType([propTypes.string, propTypes.number]),

   /**
    * 🚫 Define the input should disabled or not
    */
   disabled: propTypes.bool,

   /**
    * ⚠ Define the input should read-only or not
    */
   readOnly: propTypes.bool,

   /**
    * 😬 Accept single react element, <span>Value</span>
    */
   children: propTypes.object,
}

export default Radio
