const configs = {
  state: {
    primary:
      "border border-transparent bg-purple-500 hover:bg-purple-450 active:bg-purple-400 text-neutral-100",
    secondary:
      "border bg-white border-neutral-400 hover:bg-neutral-300 active:bg-neutral-200 text-neutral-1000",
    tertiary: "border text-neutral-1000",
    disabled:
      "select-none relative border border-neutral-400 bg-neutral-300 text-neutral-600 cursor-not-allowed",
  },
  color: {
    link: "border border-transparent text-blue-500",
    info: "border border-transparent text-blue-500",
    success:
      "border border-green-500 hover:bg-green-300 active:border-green-400 text-green-500",
    warning:
      "border border-yellow-500 hover:bg-yellow-300 active:border-yellow-400 text-yellow-500",
    danger:
      "border border-red-500 hover:bg-red-300 active:border-red-400 text-red-500",
  },
  size: {
    sm: "text-xs h-8",
    md: "text-sm h-10",
    lg: "text-base h-12",
  },
};

export default configs;
