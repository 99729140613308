import React, { useState, Children } from "react";
import propTypes from "prop-types";

import {
  Input,
  Label,
} from "@goalabs.id/react-shared-components/dist/components/Form/";
import configs from "./configs";

/**
 * Form / Switch;
 * UI component for input type checkbox and present as a Switch Toggler UI 🔴⚪
 */
const Switch = (props) => {
  const [state, setState] = useState(
    () => props.checked || props.defaultChecked || false
  );

  const id = `${Math.random(100)}-${props.id}`;

  const notAllowed = props.disabled || props.readOnly;

  return (
    <Label
      htmlFor={id}
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      className="select-none cursor-pointer"
    >
      <Input
        disabled={props.disabled}
        readOnly={props.readOnly}
        id={id}
        name={props.name}
        type="checkbox"
        style={{ display: "none" }}
        value={props.value}
        onChange={(event) => {
          setState((prev) => !prev);
          props.onChange && props.onChange(event);
        }}
        checked={state}
      />
      <div
        className={[
          "rounded-full relative transition-all duration-200",
          notAllowed
            ? "bg-neutral-100"
            : state
            ? "bg-yellow-500"
            : "bg-neutral-300",
          configs?.size?.[props.size]?.track || "",
        ].join(" ")}
      >
        <div
          className={[
            "bg-white rounded-full absolute transform transition-all duration-200 ",
            configs?.size?.[props.size]?.knob || "",
            notAllowed
              ? "bg-neutral-400"
              : state
              ? "shadow-lg -translate-x-full left-full"
              : "shadow-lg ringtranslate-x-0 left-0",
          ].join(" ")}
        ></div>
      </div>
      {Children.toArray(props.children)[state ? 1 : 0]}
    </Label>
  );
};

Switch.defaultProps = {
  size: "md",
};

Switch.propTypes = {
  /**
   * 🎚 Define the button size
   */
  size: propTypes.oneOf(["sm", "md", "lg"]),

  /**
   * input and label id
   */
  id: propTypes.string.isRequired,

  /**
   * input name
   */
  name: propTypes.string.isRequired,

  /**
   * ✨ Custom css class
   */
  className: propTypes.string,

  /**
   * 🎨 Custom style
   */
  style: propTypes.object,

  /**
   * ✅ Define the input default checked status in first load
   */
  defaultChecked: propTypes.bool,

  /**
   * 📝 Define the input value
   */
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),

  /**
   * 🚫 Define the input should disabled or not
   */
  disabled: propTypes.bool,

  /**
   * ⚠ Define the input should read-only or not
   */
  readOnly: propTypes.bool,

  /**
   * 😬 Accept maximum 2 nodes react element, &lt;span&gt;Value&lt;/span&gt;, you can send more than that. I will ignore it anyway 😗
   */
  children: propTypes.array.isRequired,
};

export default Switch;
