import React from "react";
import { Switch, Route } from "react-router-dom";

import Layout from "parts/Layout";
import Documents from "parts/Documents";

import List from "./List";
import Form from "./Form";

export default function SalesVendor() {
  return (
    <Documents>
      <Layout>
        <Switch>
          <Route exact path="/vendors" component={List} />
          <Route path="/vendors/list" component={List} />
          <Route path="/vendors/add" component={Form} />
          <Route path="/vendors/:id" component={Form} />
        </Switch>
      </Layout>
    </Documents>
  );
}
