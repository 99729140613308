import React from "react";
import Print from "components/Print";
import format from "date-fns/format";
import "helpers/format/currency";
import "helpers/format/thousand";
import "helpers/format/getInitialName";
import Input from "components/Form/Input";
import useForm from "helpers/hooks/useForm";

export default function ModalInvoice({ item }) {
  const [payload, setPayload] = useForm({
    discount: "0",
    item: [],
    isPaid: false,
    hasBiayaInstalasi: true,
    removeOthersRincian: false,
    invoiceDate: new Date(new Date().getFullYear(), new Date().getMonth(), 25),
    dueDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 5),
    rincian: {
      "biaya-instalasi": {
        item: "Biaya instalasi aplikasi mejadokter (termasuk import data produk, staff dan training aplikasi)",
        satuan: 1000000,
        qty: 1,
      },
    },
  });

  let totalRincian =
    Number(payload.rincian["biaya-instalasi"].qty) *
    Number(payload.rincian["biaya-instalasi"].satuan);

  return (
    <Print title={`Quotation ${item.clinicName} ${item.ownerName}`}>
      {/* {payload.isPaid && (
        <div className="absolute top-0 bottom-32 left-32 right-32 z-40 flex items-center justify-center">
          <img src="/images/lunas.png" alt="" />
        </div>
      )} */}
      <div className="flex justify-between">
        <div className="">
          <div className="w-40 h-12">
            <img
              src="/images/logo-main.png"
              alt="Logo Mejadokter"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
        <div className="text-right">
          <h1 className="text-4xl">INVOICE</h1>
          <h6>
            #{item.clinicName.getInitialName()}/{format(new Date(), "yyyy/MM")}
            /01
          </h6>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-96">
          <h6 className="text-xl font-light">Mejadokter</h6>
          <p className="text-xs font-extrabold">
            PT DLN KARYA BANGSA <br />
            Jln Pulau Menjangan No. 61a Banyuning Selatan, <br />
            Singaraja, Bali <br />
            +6285163639192
          </p>

          <h6 className="font-extrabold mt-8">Bill To:</h6>
          <h6 className="text-xl font-light mt-2">{item.clinicName}</h6>
          <p className="text-xs font-extrabold">{item.clinicAddress}</p>
          <p className="text-xs font-extrabold">{item.clinicPhoneNumber}</p>
        </div>
        <div className="text-right self-end">
          <h6 className="text-xs font-extrabold mt-2">Date: </h6>
          <p className="font-light text-right hidden print:inline-flex">
            {format(payload.invoiceDate, "dd LLLL yyyy")}
          </p>
          <Input
            size="sm"
            type="date"
            state="default"
            id="invoiceDate"
            name="invoiceDate"
            // placeholder="Diskon"
            className="print:hidden"
            onChange={(event) =>
              setPayload({
                target: {
                  name: event.target.name,
                  value: new Date(event.target.value),
                },
              })
            }
            defaultValue={format(payload.invoiceDate, "yyyy-MM-dd")}
          />

          <h6 className="text-xs font-extrabold mt-2">Due Date: </h6>
          <p className="font-light text-right hidden print:inline-flex">
            {format(payload.dueDate, "dd LLLL yyyy")}
          </p>
          <Input
            size="sm"
            type="date"
            state="default"
            id="dueDate"
            name="dueDate"
            // placeholder="Diskon"
            className="print:hidden"
            onChange={(event) =>
              setPayload({
                target: {
                  name: event.target.name,
                  value: new Date(event.target.value),
                },
              })
            }
            defaultValue={format(payload.dueDate, "yyyy-MM-dd")}
          />

          <h6 className="text-xs font-extrabold mt-2">Balance Due: </h6>
          <p className="text-3xl font-light">
            {(totalRincian - Number(payload.discount)).currency()}
          </p>
        </div>
      </div>
      <div className="my-10">
        <div className="bg-yellow-500 py-1 mt-8 rounded">
          <div className="flex px-4">
            <div className="w-6/12">Item</div>
            <div className="w-1/12">Quantity</div>
            <div className="w-2/12 text-right">Rate</div>
            <div className="w-3/12 text-right">Amount</div>
          </div>
        </div>
        {Object.keys(payload.rincian).map((id) => {
          const item = payload.rincian[id];

          return (
            <div className="flex px-4 mt-2" key={id}>
              <div className="w-6/12">{item.item}</div>
              <div className="w-1/12">
                <p className="text-right hidden print:inline-flex">
                  {Number(item.qty).thousand()}
                </p>
                <Input
                  size="sm"
                  type="number"
                  state="default"
                  id={`qty-${id}`}
                  name={`qty`}
                  // placeholder="Diskon"
                  className="print:hidden w-full"
                  onChange={(e) => {
                    setPayload({
                      target: {
                        name: "rincian",
                        value: {
                          ...payload.rincian,
                          "biaya-instalasi": {
                            ...payload.rincian["biaya-instalasi"],
                            [e.target.name]: e.target.value,
                          },
                        },
                      },
                    });
                  }}
                  defaultValue={item.qtyBiayaInstalasi}
                />
              </div>
              <div className="w-2/12 text-right">
                <Input
                  size="sm"
                  type="number"
                  state="default"
                  id={`satuan-${id}`}
                  name={`satuan`}
                  // placeholder="Diskon"
                  className="print:hidden w-full"
                  onChange={(e) => {
                    setPayload({
                      target: {
                        name: "rincian",
                        value: {
                          ...payload.rincian,
                          "biaya-instalasi": {
                            ...payload.rincian["biaya-instalasi"],
                            [e.target.name]: e.target.value,
                          },
                        },
                      },
                    });
                  }}
                  defaultValue={item.qtyBiayaInstalasi}
                />
              </div>
              <div className="w-3/12 text-right">
                {Number(
                  Number(payload.rincian["biaya-instalasi"].qty) *
                    Number(payload.rincian["biaya-instalasi"].satuan)
                ).currency()}
              </div>
            </div>
          );
        })}
        <div className="flex px-4 mt-8">
          <div className="w-6/12"></div>
          <div className="w-1/12"></div>
          <div className="w-2/12 text-right">Subtotal:</div>
          <div className="w-3/12 text-right">{totalRincian.currency()}</div>
        </div>
        {Number(payload.discount) > 0 && (
          <div className="px-4 mt-2 hidden print:flex">
            <div className="w-6/12"></div>
            <div className="w-1/12"></div>
            <div className="w-2/12 text-right">Potongan Harga:</div>
            <div className="w-3/12 text-right">
              {Number(payload.discount).currency()}
            </div>
          </div>
        )}
        <div className="flex px-4 mt-2 print:hidden">
          <div className="w-6/12"></div>
          <div className="w-1/12"></div>
          <div className="w-2/12 text-right">Potongan Harga:</div>
          <div className="w-3/12 text-right">
            <Input
              size="sm"
              state="default"
              id="discount"
              name="discount"
              placeholder="Diskon"
              className="text-right"
              onChange={setPayload}
              required
              defaultValue={payload.discount}
            />
          </div>
        </div>
        <div className="flex px-4 mt-2">
          <div className="w-6/12"></div>
          <div className="w-1/12"></div>
          <div className="w-2/12 text-right">Total:</div>
          <div className="w-3/12 text-right">
            {(totalRincian - Number(payload.discount)).currency()}
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-56">
        <div className="">
          <p className="text-xs font-extrabold">
            Mohon untuk melakukan pengiriman dana sejumlah tagihan ke:
          </p>

          {/* <h6 className="text-xs font-extrabold mt-2">Jenius $Cashtag:</h6>
          <p className="text-xl font-light">$mejadokter</p>

          <h6 className="text-xs font-extrabold">Account number:</h6>
          <p className="text-xl font-light">90210131061</p> */}

          <h6 className="text-xs font-extrabold mt-2">Nama Bank:</h6>
          <p className="text-xl font-light">Bank Central Asia (BCA)</p>

          <h6 className="text-xs font-extrabold">Account number:</h6>
          <p className="text-xl font-light">8271123833</p>

          <h6 className="text-xl font-light mt-2">Kebijakan Berlaku:</h6>
          <p className="text-xs font-extrabold">
            Keterlambatan pembayaran akan ditindaklanjuti sesuai kesepakatan
            yang tertera pada MoU
          </p>
        </div>
      </div>
    </Print>
  );
}
