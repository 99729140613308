import React from "react";
import ReactToPrint from "react-to-print";
import Group from "@goalabs.id/react-shared-components/dist/components/Group/";
import Button from "components/Button/";

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div className="print-element" ref={ref}>
      {props.children}
    </div>
  );
});

const Print = ({ children, title = "Some Title" }) => {
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const onBeforeGetContentResolve = React.useRef(null);
  const componentRef = React.useRef(null);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  return (
    <div>
      <style media="print">{`
            @page {
              margin: 1cm;
            }
        `}</style>
      <ComponentToPrint ref={componentRef}>{children}</ComponentToPrint>

      <div className="sticky bottom-0 left-0 right-0 z-10 bg-white container-lg mx-auto py-2 ">
        <Group
          className="evenly-space"
          style={{ marginTop: 32, alignItems: "center" }}
        >
          <ReactToPrint
            documentTitle={title}
            onBeforeGetContent={handleOnBeforeGetContent}
            trigger={() => (
              <Button
                loading={loading}
                size="sm"
                state="primary"
                className="px-6"
              >
                <button className="">Print</button>
              </Button>
            )}
            content={reactToPrintContent}
          />
        </Group>
      </div>
    </div>
  );
};

export default Print;
