import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import Swal from "components/Swal";

import Spinner from "components/Spinner";
import Button from "components/Button/";

import "helpers/format/date";
import Group from "components/Group";

import format from "date-fns/format";

import { deleteOne } from "configs/api/leads";

import modal from "components/Modal";
import ModalUpdateLeads from "../Form/ModalUpdateLeads";
import ModalQuotation from "../ModalQuotation";
import ModalInvoice from "../ModalInvoice";

export default function Table({ isLoading, isIdle, data, getData, term }) {
  const fnStatus = useCallback((item) => {
    modal.show(<ModalUpdateLeads item={item} />, {
      wrapper: {
        style: {
          width: 850,
          padding: 0,
        },
      },
    });
  }, []);

  const fnQuotation = useCallback((item) => {
    modal.show(<ModalQuotation item={item} />, {
      outer: {
        style: {
          position: "fixed",
          overflowX: "hidden",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 40,
        },
      },
      wrapper: {
        style: {
          width: 1050,
          padding: 80,
        },
      },
    });
  }, []);

  const fnInvoice = useCallback((item) => {
    modal.show(<ModalInvoice item={item} />, {
      outer: {
        style: {
          position: "fixed",
          overflowX: "hidden",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 40,
        },
      },
      wrapper: {
        style: {
          width: 1050,
          padding: 80,
        },
      },
    });
  }, []);

  if (isIdle || isLoading)
    return (
      <span className="h-44 flex items-center justify-center w-full">
        <Spinner />
      </span>
    );

  function fnDelete(item) {
    Swal.fire({
      title: <p>Hapus Leads?</p>,
      text: `Yakin hapus leads ${item.clinicName}`,
    }).then(async () => {
      try {
        const res = await deleteOne(item.id);

        if (res) {
          getData();
          return Swal.fire(<p>Data terhapus</p>);
        }
      } catch (error) {}
    });
  }

  return (
    <section id="items" className="px-4 lg:px-12 pt-6">
      {/* <div className="flex bg-slate-100 text-xs uppercase py-2">
        div.
      </div> */}
      <table className="w-full text-sm">
        <thead className="hidden lg:table-header-group">
          <tr>
            <th width="5%" className="bg-slate-100 text-xs uppercase py-2"></th>
            <th
              width="50%"
              className="text-left bg-slate-100 text-xs uppercase py-2"
            >
              Informasi Pemilik
            </th>
            <th
              width="25%"
              className="text-left bg-slate-100 text-xs uppercase py-2"
            >
              Waktu
            </th>
            <th width="20%" className="bg-slate-100 text-xs uppercase py-2">
              Tools
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.payload
            .filter((item) =>
              item?.ownerName?.toLowerCase().includes(term.toLowerCase())
            )
            .map((item, n) => {
              return (
                <tr
                  key={item.id}
                  className="odd:bg-white even:bg-slate-100 lg:table-row"
                >
                  <td className="px-0 lg:px-2 py-0 lg:py-4 table-row lg:table-cell">
                    {n + 1}.
                  </td>
                  <td className="px-0 lg:px-2 py-0 lg:py-4 table-row lg:table-cell">
                    <h6>{item.ownerName}</h6>
                    <p>{item.ownerPhoneNumber}</p>
                  </td>
                  <td className="px-0 lg:px-2 py-0 lg:py-4 table-row lg:table-cell">
                    <p className="text-xs">
                      Last Update: {item.updatedAt.date()}{" "}
                      {format(new Date(item.updatedAt), "hh:mm aaa")}
                    </p>
                    <p className="text-xs">
                      Created at: {item.createdAt.date()}{" "}
                      {format(new Date(item.createdAt), "hh:mm aaa")}
                    </p>
                  </td>
                  <td className="px-0 lg:px-2 py-0 lg:py-4 table-row lg:table-cell">
                    <Group type="adjacent" className="pb-4 pt-2 lg:mb-0">
                      <Button size="sm" state="secondary" className="px-2">
                        <Link to={`/leads/${item.id}`}>Edit</Link>
                      </Button>
                      <Button
                        size="sm"
                        state="secondary"
                        className="px-2"
                        onClick={() => fnQuotation(item)}
                      >
                        <button>Quotation</button>
                      </Button>
                      <Button
                        size="sm"
                        state="secondary"
                        className="px-2"
                        onClick={() => fnInvoice(item)}
                      >
                        <button>Invoice</button>
                      </Button>
                      <Button
                        size="sm"
                        state="secondary"
                        className="px-2"
                        onClick={() => fnStatus(item)}
                      >
                        <button>Timeline</button>
                      </Button>
                      <Button
                        size="sm"
                        state="secondary"
                        className="px-2"
                        onClick={() => fnDelete(item)}
                      >
                        <button>Delete</button>
                      </Button>
                    </Group>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </section>
  );
}
