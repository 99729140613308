import React from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Dropdown from "components/Dropdown/";
import Avatar from "components/Avatar";

export default function Header() {
  const history = useHistory();

  function logout() {
    localStorage.removeItem("token");
    history.push("/login");
  }

  const initialList = [
    {
      id: "profile-settings",
      value: "Profile Settings",
      name: "Profile Settings",
      display: <span className="py-1">Profile Settings</span>,
      onSelect: () => {
        history.push("/profile");
      },
    },
    {
      id: "billing",
      value: "billing",
      name: "Billing",
      display: <span className="py-1">Billing</span>,
      onSelect: () => {
        history.push("/billing");
      },
    },
    {
      id: "logout",
      value: "logout",
      name: "Logout",
      display: <span className="py-1">Logout</span>,
      onSelect: () => {
        logout();
      },
    },
  ];

  return (
    <header className={["my-6 px-12"].join(" ")}>
      <div className="flex flex-stretch items-center">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="w-full"></div>
        <div className="w-auto">
          <ul
            className={["flex flex-col items-center justify-center"].join(" ")}
          >
            <li className="-mr-2 py-6 flex">
              <Dropdown
                name="phone-country1"
                className="border-none rounded-full h-12"
                value=""
                list={initialList}
                configs={{
                  icon: { display: false },
                  list: {
                    position: "right",
                    default: (
                      <Avatar>
                        <img
                          src="/images/content/willycece.jpg"
                          alt="willycece"
                        />
                      </Avatar>
                    ),
                    empty: "No event added",
                    closeOnSelect: true,
                    setCurrentOnSelect: false,
                  },
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

Header.defaultProps = {
  position: "relative",
};

Header.propTypes = {
  theme: propTypes.oneOf(["white", "black"]),
  position: propTypes.oneOf(["absolute", "relative"]),
};
