import React from "react";

import Input from "components/Form/Input";
// import Dropdown from "components/Dropdown";
import Icon from "components/Icon";

export default function Filter({ filter, setFilter, term, setTerm }) {
  return (
    <section id="items" className="px-12 pt-4">
      <div className="flex flex-wrap -mx-4">
        {/* <div className="px-4 w-auto">
          <Dropdown
            className="px-4"
            configs={{
              list: {
                closeOnSelect: true,
                default: "Filter",
                empty: "No option available",
              },
            }}
            list={[
              {
                display: <span>Pompa Air</span>,
                id: "filter-1",
                name: "Pompa Air",
                onSelect: () => {
                  setFilter("filter-1");
                },
                value: "filter-1",
              },
            ]}
            value={filter}
            name="filter"
          />
        </div> */}
        <div className="px-4 w-auto md:w-[300px]">
          <div className="relative">
            <Input
              className="w-full"
              value={term}
              id="search"
              name="search"
              onChange={(e) => {
                setTerm(e.target.value);
              }}
              placeholder="Pencarian"
            />
            <span
              onClick={() => setTerm("")}
              className="absolute right-0 top-0 w-10 h-full flex items-center justify-center text-neutral-1000 cursor-pointer"
            >
              <Icon
                item={term ? "close-outline" : "search-outline"}
                size="16"
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
