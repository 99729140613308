// import axios, { setAuthorizationHeader } from "./index";

// import { refresh } from "configs/api/oAuth";
const errorHandler = async (error) => {
  if (error) {
    // if (error?.response?.data?.errors?.global === "Token Expired") {
    //   const originalRequest = error.config;
    // if (!originalRequest._retry) {
    //   originalRequest._retry = true;
    // const tokenRefresh = localStorage.getItem("tokenRefresh");
    // if (tokenRefresh) {
    //   try {
    //     const refreshed = await refresh(tokenRefresh);
    //     if (refreshed) {
    //       const { user } = refreshed.data;
    //       localStorage.token = user.token;
    //       localStorage.tokenRefresh = user.tokenRefresh;
    //       setAuthorizationHeader(user.token);
    //       originalRequest.headers.authorization = `Bearer ${user.token}`;
    //       return axios(originalRequest);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("tokenRefresh");
    //     localStorage.removeItem("user");
    //     window.location.href = "/login";
    //   }
    // }
    // }
    // }
  }
  return Promise.reject(error);
};

export default errorHandler;
