import { LOGIN, LOGOUT } from "../types/user";

export const setLogin = (payload = null) => ({
  type: LOGIN,
  payload: payload,
});

export const setLogout = () => ({
  type: LOGOUT,
});
