import axios from "configs/axios";

export const getAll = (params = {}, headers = {}) =>
  axios({
    url: `/leads`,
    method: "GET",
    headers,
    params,
  });

export const getOne = (id, headers = {}) =>
  axios({
    url: `/leads/${id}`,
    method: "GET",
    headers,
  });

export const createOne = (data) =>
  axios({
    url: `/leads`,
    method: "POST",
    data,
  });

export const updateOne = (id, data) =>
  axios({
    url: `/leads/${id}`,
    method: "PUT",
    data,
  });

export const getTimelines = (id, data) =>
  axios({
    url: `/leads/${id}/timelines`,
    method: "GET",
    data,
  });

export const createTimelines = (id, data) =>
  axios({
    url: `/leads/${id}/timelines`,
    method: "POST",
    data,
  });

export const deleteOne = (id, headers = {}) =>
  axios({
    url: `/leads/${id}`,
    method: "DELETE",
    headers,
  });
