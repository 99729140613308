import React, { useCallback, useLayoutEffect, useRef, useState } from "react";

import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import general from "configs/components/sidebar/general";
// import clinicSelected from "configs/components/sidebar/clinicSelected";

import Icon from "components/Icon";
import { ReactComponent as Logo } from "assets/images/logo-horizontal.svg";

import { setLogout } from "redux/actions/user";
import { toggleSidebar } from "redux/actions/components";

export default function Sidebar() {
  const [active, setActive] = useState(null);
  const user = useSelector((state) => state.user);
  const components = useSelector((state) => state.components);

  const dispatch = useDispatch();

  const refSidebar = useRef();

  const isAllowed = user.admin
    ? ["dashboard", "leads", "vendors", "users"]
    : ["leads"];

  const availableMenu = isAllowed.reduce((acc, item) => {
    acc[item] = general[item];
    return acc;
  }, {});

  function fnToggle(event, collection, item) {
    if (!collection.isAnchor) return;
    event.preventDefault();
    setActive((prev) => (item === prev ? null : item));
  }

  const renderItems = (current) => {
    return Object.keys(current).map((item) => {
      const basePath = "";
      return (
        <li key={item} className="">
          <NavLink
            onClick={(event) => {
              fnToggle(event, current[item], item);
              dispatch(toggleSidebar(false));
            }}
            exact={current[item].exact}
            to={`${basePath}${current[item].to}`}
            activeClassName="active"
            className={[
              "no-underline text-neutral-200 flex px-5 py-3",
              active === item ? "active" : "",
            ].join(" ")}
          >
            <Icon item={current[item].icon} className="mr-3" size="22" />
            {current[item].text}
          </NavLink>
          {current[item].child instanceof Object && (
            <ul className="flex flex-col">
              {renderItems(current[item].child)}
            </ul>
          )}
        </li>
      );
    });
  };

  function fnLogout() {
    dispatch(setLogout());
    localStorage.removeItem("user");
  }

  const handleClickOutside = useCallback(
    (event) => {
      if (
        refSidebar &&
        refSidebar.current &&
        !refSidebar.current.contains(event.target)
      ) {
        dispatch(toggleSidebar(false));
      }
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <aside
      ref={refSidebar}
      className={[
        "z-40 rounded-none lg:rounded-tl-xl lg:rounded-bl-xl bg-slate-900 sidebar fixed inset-0 lg:relative lg:flex flex-col justify-between",
        components.sidebar.isVisible ? "flex" : "hidden",
      ].join(" ")}
      style={{ width: 300 }}
    >
      <div className="sticky top-0">
        <div className="px-3 mb-4 mt-3">
          <div className="p-10 relative">
            <span className="font-bold text-lg text-white">
              <Link to="/">
                <Logo className="logo yellow w-32 mx-auto" />
              </Link>
            </span>
            <h6 className="-mx-10 text-white text-center">
              {user.user.name} ({user.user.email})
            </h6>
            <h6 className="-mx-10 text-white text-center">
              {user.user.vendor.name}
            </h6>
          </div>
        </div>
        <ul className="text-white">{renderItems(availableMenu)}</ul>
      </div>

      <ul className="text-white mt-12">
        <li className="cursor-pointer" onClick={fnLogout}>
          <span className="flex px-5 py-3">
            <Icon item="log-out-outline" className="mr-3" size="22" />
            Logout
          </span>
        </li>
      </ul>
    </aside>
  );
}
