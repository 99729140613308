import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import GuestRoute from "parts/Routes/GuestRoute";
import SessionRoute from "parts/Routes/SessionRoute";
// import ClinicRoute from "parts/Routes/ClinicRoute";

import modalConfigs from "configs/components/modalProvider";
import { ModalProvider } from "components/Modal";

import Login from "pages/Login";
import NotFound from "pages/NotFound";
import Dashboard from "pages/Dashboard";

import SalesVendor from "pages/SalesVendor";
import SalesUser from "pages/SalesUser";
import SalesLeads from "pages/SalesLeads";

import "assets/css/app.css";

import { setLogin } from "redux/actions/user";
import { setAuthorizationHeader } from "configs/axios";
import { useDispatch } from "react-redux";

import jwt_decode from "jwt-decode";

function App() {
  const [state, setstate] = useState("propagating");
  const dispatch = useDispatch();
  // const history = useHistory();

  useEffect(() => {
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);
      const session = jwt_decode(user.token);

      dispatch(setLogin(session));
      setAuthorizationHeader(user.token);
    }

    setstate("ready");
  }, [dispatch]);

  if (state === "propagating") return "Loading...";
  if (state === "ready")
    return (
      <BrowserRouter>
        <ModalProvider options={modalConfigs} />
        <Switch>
          <SessionRoute exact path="/" component={Dashboard} />
          <SessionRoute path="/dashboard" component={Dashboard} />

          <SessionRoute path="/vendors" component={SalesVendor} />
          <SessionRoute path="/users" component={SalesUser} />
          <SessionRoute path="/leads" component={SalesLeads} />

          <GuestRoute path="/login" component={Login} />
          <GuestRoute path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    );

  return null;
}

export default App;
