import React from "react";
import propTypes from "prop-types";

import configs from "./configs";

/**
 * UI component to show avatar of a person
 */
const Avatar = (props) => {
  const className = {
    wrapper: [
      "rounded-full overflow-hidden relative inline-flex justify-center items-center bg-yellow-200 text-yellow-500 font-bold",
      configs?.size?.[props.size]?.wrapper || "",
      props.className || "",
    ].join(" "),
    indicator: [
      "rounded-full absolute bottom-0 right-0",
      configs?.state?.[props.state] || "",
      configs?.size?.[props.size]?.indicator || "",
    ].join(" "),
  };

  return (
    <div className={className.wrapper} style={props.style}>
      {props.state !== "default" && <div className={className.indicator}></div>}
      {props.children}
    </div>
  );
};

Avatar.defaultProps = {
  state: "default",
  size: "md",
};

Avatar.propTypes = {
  /**
   * ♥ Define current state of alert
   */
  state: propTypes.oneOf(["online", "offline", "idle", "default"]),

  /**
   * 🎚 Define the button size
   */
  size: propTypes.oneOf(["sm", "md", "lg"]),

  /**
   * 🎨 Custom css class
   */
  className: propTypes.string,

  /**
   * ✨ Custom element styling
   */
  style: propTypes.object,

  /**
   * 😬 Accept either react element or string for text only, I recommend you use children to show an img tag
   */
  children: propTypes.oneOfType([
    propTypes.object,
    propTypes.array,
    propTypes.string,
  ]),
};

export default Avatar;
