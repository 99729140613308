import React from "react";
import Print from "components/Print";
import format from "date-fns/format";
import "helpers/format/currency";
import "helpers/format/thousand";
import "helpers/format/getInitialName";
import Input from "components/Form/Input";
import useForm from "helpers/hooks/useForm";

export default function ModalQuotation({ item }) {
  console.log(item);

  const [payload, setPayload] = useForm({
    umum: {
      name: "Biaya penanganan pasien Umum atau asuransi lainnya",
      value: 3000,
      satuan: "transaksi",
      description: "biaya transaksi tiap kunjungan pasien",
    },
    bpjs: {
      name: "Biaya penanganan pasien BPJS",
      value: 500,
      satuan: "transaksi",
      description: "biaya transaksi tiap kunjungan pasien",
    },
    apotek: {
      name: "Biaya penanganan belanja di apotek",
      value: 100,
      satuan: "transaksi",
      description: "biaya transaksi tiap kunjungan pasien",
    },
    biayaInstalasi: {
      name: "Biaya instalasi untuk 1 faskes",
      value: 1000000,
      satuan: "one time charge",
      description:
        "Biaya instalasi awal yang dikenakan kepada mitra mejadokter yang meliputi: Pelatihan penggunaan aplikasi, import pasien, staff, dan inventory product.",
    },
  });

  return (
    <Print title={`Quotation ${item.clinicName} ${item.ownerName}`}>
      <div className="flex justify-between">
        <div className="">
          <div className="w-40 h-12">
            <img
              src="/images/logo-main.png"
              alt="Logo Mejadokter"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
        <div className="text-right">
          <h1 className="text-4xl">QUOTATION</h1>
          <h6>
            {(item.clinicName || "").getInitialName()}01/
            {format(new Date(), "dd/MM/yyyy")}
          </h6>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-96">
          <h6 className="text-xl font-light">Mejadokter</h6>
          <p className="text-xs font-extrabold">
            PT DLN KARYA BANGSA <br />
            Jln Pulau Menjangan No. 61a Banyuning Selatan, <br />
            Singaraja, Bali <br />
            +6285163639192
          </p>

          <h6 className="font-extrabold mt-8 tracking-[5px] uppercase">
            Offering To
          </h6>
          <h6 className="text-xl font-light mt-2">{item.clinicName}</h6>
          <p className="text-xs font-extrabold">{item.ownerName}</p>
          <p className="text-xs font-extrabold">{item.clinicAddress}</p>
          <p className="text-xs font-extrabold">{item.clinicPhoneNumber}</p>
        </div>
      </div>
      <div className="my-10">
        <div className="bg-yellow-500 py-1 mt-8 rounded">
          <div className="flex px-4">
            <div className="w-5/12">Item</div>
            <div className="w-3/12">Rate</div>
            <div className="w-4/12">Keterangan</div>
          </div>
        </div>
        {Object.keys(payload).map((id) => {
          const item = payload[id];

          return (
            <div className="flex px-4 mt-2" key={id}>
              <div className="w-5/12 text-sm">{item.name}</div>
              <div className="w-3/12 text-sm">
                <p className="hidden print:inline-flex">
                  Rp.{Number(item.value).thousand()}/{item.satuan}
                </p>
                <Input
                  size="sm"
                  type="number"
                  state="default"
                  id={`value`}
                  name={`value`}
                  // placeholder="Diskon"
                  className="print:hidden"
                  onChange={(e) =>
                    setPayload({
                      target: {
                        name: id,
                        value: {
                          ...item,
                          value: e.target.value,
                        },
                      },
                    })
                  }
                  defaultValue={item.value}
                />
                <p className="inline-flex print:hidden">/{item.satuan}</p>
              </div>
              <div className="w-4/12 text-xs">{item.description}</div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col justify-between mb-56">
        <p className="text-sm mb-3 font-medium">
          Biaya diatas sudah termasuk seluruh fitur yang tersedia di dalam
          aplikasi mejadokter dan feature update yang akan terjadi dikemudian
          hari tanpa adanya tambahan biaya apapun, meliputi:
        </p>

        <ul className="grid grid-cols-2 list-disc pl-5 text-sm mb-6">
          <li>Elektronik Rekam Medis</li>
          <li>Penyimpanan Data Tanpa Batas</li>
          <li>Stock Opname</li>
          <li>Automated Medical Paperworks</li>
          <li>Dasbor Utama sebagai bird eye view</li>
          <li>Inventory Analytical Tools.</li>
          <li>Pengaturan Akun</li>
          <li>Manajemen Antrian</li>
          <li>Pemesanan Online</li>
        </ul>

        <p className="text-sm mb-3 font-medium">
          Biaya diatas sudah termasuk seluruh fitur yang tersedia di dalam
          aplikasi mejadokter dan feature update yang akan terjadi dikemudian
          hari tanpa adanya tambahan biaya apapun, meliputi:
        </p>

        <ul className="grid grid-cols-2 list-disc pl-5 text-sm mb-6">
          <li>Koneksi internet stabil</li>
          <li>Consumer ID dari BPJS-PCARE (jika melayani BPJS)</li>
          <li>minimal 1 buah PC (rekomendasi 2)</li>
        </ul>

        <p className="text-sm mb-3 font-medium">
          Jika terdapat pertanyaan lebih lanjut mengenai penawaran ini dapat
          langsung menghubungi: <br />
          (Yein) +62 851-6363-9192
        </p>
      </div>
    </Print>
  );
}
