import React, { useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import Button from "components/Button/";
import Icon from "components/Icon";

// import Filter from "./Filter";
import Table from "./Table";

import useAsync from "helpers/hooks/useAsync";
import { getAll } from "configs/api/users";

export default function List() {
  // const [filter, setFilter] = useState("");
  // const [term, setTerm] = useState("");
  const { vendorId } = useParams();
  const { data, run, isLoading, isIdle } = useAsync();

  const getData = useCallback(() => {
    run(getAll());
  }, [run]);

  useEffect(() => {
    getData();
  }, [getData]);

  const vendor = vendorId ? `/vendors/${vendorId}` : "";

  return (
    <div className="mb-12">
      <section id="items" className="px-12 pt-12">
        <div className="flex justify-between items-center flex-wrap -mx-4">
          <div className="px-4 w-auto">
            <h3 className="text-xl">Users</h3>
          </div>
          <div className="px-4 w-auto">
            <Button state="primary" className="px-6">
              <Link to={`/users/add${vendor}`}>
                <Icon size="16" item="edit-outline" />
                <span className="ml-2">Create New</span>
              </Link>
            </Button>
          </div>
        </div>
      </section>

      {/* <Filter
        filter={filter}
        setFilter={setFilter}
        term={term}
        setTerm={setTerm}
      /> */}

      <Table
        isLoading={isLoading}
        isIdle={isIdle}
        data={data}
        getData={getData}
      />
    </div>
  );
}
