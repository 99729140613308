/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
String.prototype.getInitialName = function () {
  const value = this.valueOf();
  let returnVal = "";

  if (value) {
    const splitName = value.split(" ");
    if (splitName.length > 1) {
      splitName.forEach((x, i) => {
        if (i <= 3) return (returnVal += x[0]);
        return "";
      });
    } else {
      returnVal = `${splitName[0][0]}${splitName[0][1]}`;
    }
  }
  return returnVal.toUpperCase();
};
