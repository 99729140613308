import React from 'react'
import propTypes from 'prop-types'

import configs from './configs'

function Spinner({ size }) {
   return (
      <svg
         className={['animate-spin', configs.size?.[size] || ''].join(' ')}
         fill="none"
         viewBox="0 0 24 24"
      >
         <path
            d="M12 24C10.8954 24 10 23.1046 10 22C10 20.8954 10.8954 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C10.8954 4 10 3.10457 10 2C10 0.89543 10.8954 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24Z"
            fill="currentColor"
         />
      </svg>
   )
}

Spinner.defaultProps = {
   size: 'md',
}

Spinner.propTypes = {
   /**
    * 🎚 Define the spinner size
    */
   size: propTypes.oneOf(['sm', 'md', 'lg']),
}

export default Spinner
