import React, { useState, Children } from 'react'
import propTypes from 'prop-types'

import {
   Input,
   Label,
} from '@goalabs.id/react-shared-components/dist/components/Form/'
// import configs from '../../configs/components/Checkbox'
import Icon from '../../Icon'

import configs from './configs'

/**
 * Form / Checkbox;
 * UI component present as a input type checkbox
 */
const Checkbox = (props) => {
   const [state, setState] = useState(
      () => props.checked || props.defaultChecked
   )

   const id = `${Math.random(100)}-${props.id}`
   let className = [
      'rounded border flex items-center justify-center cursor-pointer',
      configs?.size?.[props.size] || '',
      props.className || '',
   ]
   if (props.disabled)
      className.push('bg-neutral-300 border-neutral-400 text-neutral-600')
   else {
      if (state) className.push('bg-yellow-500 border-transparent text-white')
      else className.push('border-neutral-400')
   }

   className = className.join(' ')

   return (
      <>
         <Label
            htmlFor={id}
            style={{
               display: 'flex',
               alignItems: 'center',
               position: 'relative',
            }}
         >
            <Input
               disabled={props.disabled}
               readOnly={props.readOnly}
               id={id}
               name={props.name}
               type="checkbox"
               style={{ display: 'none' }}
               value={props.value}
               checked={props.checked ? props.checked : undefined}
               defaultChecked={
                  props.defaultChecked ? props.defaultChecked : undefined
               }
               onChange={() => setState((prev) => !prev)}
            />
            <div className={className}>
               {state ? (
                  <Icon
                     size={12}
                     item={
                        props.indeterminate
                           ? 'minus-outline'
                           : 'checkmark-outline'
                     }
                  />
               ) : null}
            </div>
            {Children.only(props.children)}
         </Label>
      </>
   )
}

Checkbox.defaultProps = {
   size: 'md',
}

Checkbox.propTypes = {
   /**
    * input and label id
    */
   id: propTypes.string.isRequired,

   /**
    * input name
    */
   name: propTypes.string.isRequired,

   /**
    * ✨ Custom css class
    */
   className: propTypes.string,

   /**
    * 🎨 Custom style
    */
   style: propTypes.object,

   /**
    * ✅ Define the input default checked status in first load
    */
   defaultChecked: propTypes.bool,

   /**
    * ✅ Define the input checked status
    */
   checked: propTypes.bool,

   /**
    * 🎚 Define the checkbox size
    */
   size: propTypes.oneOf(['md', 'lg']),

   /**
    * 📝 Define the input default value
    */
   defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number]),

   /**
    * 📝 Define the input value
    */
   value: propTypes.oneOfType([propTypes.string, propTypes.number]),

   /**
    * Define the input is indeterminate [✅][❌][❌]
    */
   indeterminate: propTypes.bool,

   /**
    * 🚫 Define the input should disabled or not
    */
   disabled: propTypes.bool,

   /**
    * ⚠ Define the input should read-only or not
    */
   readOnly: propTypes.bool,

   /**
    * 😬 Accept single react element, <span>Value</span>
    */
   children: propTypes.object,
}

export default Checkbox
