import React from "react";
import { Link } from "react-router-dom";

import Swal from "components/Swal";

import Spinner from "components/Spinner";
import Button from "components/Button/";

import "helpers/format/date";
import Group from "components/Group";

import { deleteOne } from "configs/api/vendors";

export default function Table({ isLoading, isIdle, data, getData }) {
  if (isIdle || isLoading)
    return (
      <span className="h-44 flex items-center justify-center w-full">
        <Spinner />
      </span>
    );

  function fnDelete(item) {
    Swal.fire({
      title: <p>Hapus Vendor?</p>,
      text: `Yakin hapus vendor ${item.name}`,
    }).then(async () => {
      try {
        const res = await deleteOne(item.id);

        if (res) {
          getData();
          return Swal.fire(<p>Data terhapus</p>);
        }
      } catch (error) {}
    });
  }

  return (
    <section id="items" className="px-12 pt-6">
      <table className="w-full text-sm">
        <thead>
          <tr>
            <th width="5%" className="bg-slate-100 text-xs uppercase py-2"></th>
            <th
              width="60%"
              className="text-left bg-slate-100 text-xs uppercase py-2"
            >
              Nama
            </th>
            <th
              width="25%"
              className="text-left bg-slate-100 text-xs uppercase py-2"
            >
              Join Date
            </th>
            <th width="10%" className="bg-slate-100 text-xs uppercase py-2">
              Tools
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.payload.map((item, n) => {
            return (
              <tr key={item.id} className="odd:bg-white even:bg-slate-100">
                <td className="px-2 py-4">{n + 1}</td>
                <td>{item.name}</td>
                <td>{item.createdAt.date()}</td>
                <td>
                  <Group type="adjacent">
                    <Button size="sm" state="secondary" className="px-2">
                      <Link to={`/vendors/${item.id}`}>Edit</Link>
                    </Button>
                    <Button
                      size="sm"
                      state="secondary"
                      className="px-2"
                      onClick={() => fnDelete(item)}
                    >
                      <button>Delete</button>
                    </Button>
                  </Group>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
}
