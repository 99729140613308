import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function SessionRoute(props) {
  const user = useSelector((state) => state.user);

  if (!user) return <Redirect to={"/login"} />;

  return <Route {...props} />;
}
