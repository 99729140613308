const configs = {
  margin: {
    y: 100,
    x: 16,
  },
  close: {
    outsideClick: true,
    closeOnEscape: true,
    display: true,
    icon: "✖",
    size: 40,
  },
  outer: {
    style: {
      position: "fixed",
      overflowX: "hidden",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 30,
    },
  },
  container: {
    style: {},
    className: "",
  },
  wrapper: {
    style: {},
    className: "z-40 rounded-xl px-4 lg:p-8 bg-white shadow",
  },
  overlay: {
    style: {},
    className: "inset-0 fixed bg-black bg-opacity-25",
  },
};
export default configs;
