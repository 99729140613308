import React from "react";
import propTypes from "prop-types";

import InputProvider from "@goalabs.id/react-shared-components/dist/components/Form/Input";
import configs from "./configs";

export const color = (state) => configs?.state?.[state]?.icon || "";

/**
 * Input element to assemble complex form, please have a look at control section to see available arguments/props
 */
const Input = (props) => {
  const className = [
    "rounded px-4 py-2 focus:outline-none transition-colors duration-300",
    configs?.size?.[props.size] || "",
    props.disabled
      ? configs?.state?.disabled?.field || ""
      : props.readOnly
      ? configs?.state?.disabled?.field || ""
      : configs?.state?.[props.state]?.field || "",
    props.className || "",
  ].join(" ");

  return (
    <InputProvider {...props} onChange={props.onChange} className={className} />
  );
};

Input.defaultProps = {
  type: "text",
  size: "md",
  state: "default",
  className: "",
};

Input.propTypes = {
  /**
   * input and label id
   */
  id: propTypes.string.isRequired,

  /**
   * input name
   */
  name: propTypes.string.isRequired,

  /**
   * input required
   */
  required: propTypes.bool,

  /**
   * ♥ Define current state of input
   */
  state: propTypes.oneOf([
    "default",
    "info",
    "success",
    "warning",
    "error",
    "custom",
  ]),

  /**
   * 🎚 Define the input size
   */
  size: propTypes.oneOf(["sm", "md", "lg"]),

  /**
   * 📚 Define the input type
   */
  type: propTypes.oneOf([
    "text",
    "email",
    "number",
    "tel",
    "password",
    "date",
    "file",
  ]),

  /**
   * ✨ Custom css class
   */
  className: propTypes.string,

  /**
   * 🎨 CSS Declarations
   */
  style: propTypes.object,

  /**
   * ✅ Determine the input is disabled or not
   */
  disabled: propTypes.bool,

  /**
   * ✅ Determine the input is readOnly or not
   */
  readOnly: propTypes.bool,

  /**
   * 📝 Define the input default value
   */
  defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number]),

  /**
   * 📝 Define the input value
   */
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),

  /**
   * 📃 Define the input placeholder
   */
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.number]),

  /**
   * 🎹 Accept a function to init something() when change
   */
  onChange: propTypes.func,
};

export default Input;
