import React from "react";
import propTypes from "prop-types";

import ButtonProvider from "@goalabs.id/react-shared-components/dist/components/Button";
import configs from "./configs";
import "@goalabs.id/react-shared-components/dist/components/Button/index.css";
import Spinner from "../Spinner";
/**
 * Primary UI component for user interaction
 */
const Button = (props) => {
  const className = [
    "font-heading relative appearance-none rounded-lg focus:outline-none transition-colors duration-300 inline-flex items-center select-none overflow-hidden",
    configs?.size?.[props.size] || "",
    configs?.state?.[props.disabled ? "disabled" : props.state] || "",
    props.className || "",
  ];

  // const paddingX = ['px-', 'pl-', 'pr-'].some(
  //    (item) => props.className.indexOf(item) > -1
  // )

  if (props.state === "tertiary" && !props.disabled && !props.loading)
    className.push(configs?.color?.[props.color] || "");

  const children = React.cloneElement(props.children, {
    ...props.children.props,
    children: (
      <>
        <span
          className={[
            "inline-flex items-center",
            props.loading ? "invisible" : "visible",
          ].join(" ")}
        >
          {props.children.props.children}
        </span>
        <span
          className={[
            "absolute inset-0 z-10 inline-flex items-center justify-center ",
            props.loading ? "top-0" : "top-16",
          ].join(" ")}
        >
          <Spinner size="sm" />
        </span>
      </>
    ),
  });

  return (
    <ButtonProvider {...props} className={className.join(" ")} ripple>
      {children}
    </ButtonProvider>
  );
};

Button.defaultProps = {
  className: "",
  size: "md",
  style: "",
  state: "primary",
};

Button.propTypes = {
  /**
   * ♥ Define current state of button
   */
  state: propTypes.oneOf(["primary", "secondary", "tertiary"]),

  /**
   * 🎨 Define the button color when state is "tertiary"
   */
  color: propTypes.oneOf(["link", "info", "danger", "warning", "success"]),

  /**
   * 🎚 Define the button size
   */
  size: propTypes.oneOf(["sm", "md", "lg"]),

  /**
   * ✨ Custom css class
   */
  className: propTypes.string,

  /**
   * ✅ Determine the button is disabled or not
   */
  disabled: propTypes.bool,

  /**
   * ✅ Determine the button is in loading state
   */
  loading: propTypes.bool,

  /**
   * 😬 Accept object one of react element [span, a, button, Link]
   */
  children: propTypes.object,

  /**
   * 🖱 Accept a function to init something()
   */
  onClick: propTypes.func,
};

export default Button;
