import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import Icon from "components/Icon";
import Input from "components/Form/Input";
import Button from "components/Button/";
import Dropdown from "components/Dropdown";

import useForm from "helpers/hooks/useForm";

import { createOne, getOne, updateOne } from "configs/api/leads";
import { getAll } from "configs/api/users";

import { useSelector } from "react-redux";

export default function Form() {
  const { leadsId } = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);

  const [payload, setPayload, updatePayload] = useForm({
    userId: user.id,
    ownerName: "",
    ownerEmail: "",
    ownerKTP: "",
    ownerPhoneNumber: "",
    ownerAddress: "",
    clinicName: "",
    clinicPhoneNumber: "",
    clinicAddress: "",
    clinicProvinsi: "",
    clinicKabupatenKota: "",
    clinicKecamatan: "",
    clinicKelurahan: "",
    clinicKodePos: "",
    clinicLogo: "",
    status: "LEADS_COLD",
  });

  const [users, setUsers] = useState([]);

  async function fnSubmit() {
    try {
      if (leadsId) {
        const res = await updateOne(leadsId, {
          ...payload,
        });
        if (res) {
          history.push("/leads");
        }
      } else {
        const res = await createOne({
          ...payload,
        });
        if (res) {
          history.push("/leads");
        }
      }
    } catch (error) {}
  }

  const getUser = useCallback(async () => {
    try {
      if (leadsId) {
        const res = await getOne(leadsId);
        const {
          userId: idUserSelected,
          ownerName,
          ownerEmail,
          ownerKTP,
          ownerPhoneNumber,
          ownerAddress,
          clinicName,
          clinicPhoneNumber,
          clinicAddress,
          clinicProvinsi,
          clinicKabupatenKota,
          clinicKecamatan,
          clinicKelurahan,
          clinicKodePos,
          clinicLogo,
          status,
        } = res.data.payload;

        updatePayload({
          userId: idUserSelected,
          ownerName: ownerName,
          ownerEmail: ownerEmail,
          ownerKTP: ownerKTP,
          ownerPhoneNumber: ownerPhoneNumber,
          ownerAddress: ownerAddress,
          clinicName: clinicName,
          clinicPhoneNumber: clinicPhoneNumber,
          clinicAddress: clinicAddress,
          clinicProvinsi: clinicProvinsi,
          clinicKabupatenKota: clinicKabupatenKota,
          clinicKecamatan: clinicKecamatan,
          clinicKelurahan: clinicKelurahan,
          clinicKodePos: clinicKodePos,
          clinicLogo: clinicLogo,
          status: status,
        });
      }
    } catch (error) {}
  }, [leadsId, updatePayload]);

  const getVendors = useCallback(async () => {
    try {
      const res = await getAll();

      setUsers(res.data.payload);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getUser();
    getVendors();
  }, [getUser, getVendors]);

  return (
    <div className="mb-12">
      <section id="items" className="px-4 pt-4 lg:px-12 lg:pt-12">
        <div className="flex flex-wrap px-4">
          <div className="-mx-4">
            <h3 className="text-xl flex items-center">
              <Link to={`/leads`} className="">
                <Icon size="22" item="arrow-back-outline" />
              </Link>
              <span className="ml-2">
                {leadsId ? "Ubah Leads" : "Tambah Leads"}{" "}
              </span>
            </h3>
          </div>
        </div>
        {user.admin && (
          <div className="mt-4 w-full lg:w-4/12 flex flex-col">
            <label htmlFor="name">Select Sales:</label>
            <Dropdown
              className="px-4"
              configs={{
                list: {
                  closeOnSelect: true,
                  default: "Sales",
                  empty: "No option available",
                },
              }}
              list={users.map((user) => {
                return {
                  display: <span>{user.name}</span>,
                  id: user.id,
                  name: user.name,
                  onSelect: () => {
                    setPayload({
                      target: {
                        name: "userId",
                        value: String(user.id),
                      },
                    });
                  },
                  value: String(user.id),
                };
              })}
              value={String(payload.userId)}
              name="userId"
            />
          </div>
        )}
        {payload.userId && (
          <>
            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="ownerName">Nama Owner:</label>
              <Input
                className="w-full"
                value={payload.ownerName}
                id="ownerName"
                name="ownerName"
                onChange={setPayload}
                placeholder="Nama Owner"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="ownerEmail">Email Owner:</label>
              <Input
                type="email"
                className="w-full"
                value={payload.ownerEmail}
                id="ownerEmail"
                name="ownerEmail"
                onChange={setPayload}
                placeholder="Email Owner"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="ownerKTP">KTP Owner:</label>
              <Input
                className="w-full"
                value={payload.ownerKTP}
                id="ownerKTP"
                name="ownerKTP"
                onChange={setPayload}
                placeholder="KTP Owner"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="ownerPhoneNumber">Phone Number Owner:</label>
              <Input
                className="w-full"
                value={payload.ownerPhoneNumber}
                id="ownerPhoneNumber"
                name="ownerPhoneNumber"
                onChange={setPayload}
                placeholder="Phone Number Owner"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="ownerAddress">Address Owner:</label>
              <Input
                className="w-full"
                value={payload.ownerAddress}
                id="ownerAddress"
                name="ownerAddress"
                onChange={setPayload}
                placeholder="Address Owner"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicName">Name Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicName}
                id="clinicName"
                name="clinicName"
                onChange={setPayload}
                placeholder="Name Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicPhoneNumber">Phone Number Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicPhoneNumber}
                id="clinicPhoneNumber"
                name="clinicPhoneNumber"
                onChange={setPayload}
                placeholder="Phone Number Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicAddress">Address Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicAddress}
                id="clinicAddress"
                name="clinicAddress"
                onChange={setPayload}
                placeholder="Address Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicProvinsi">Provinsi Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicProvinsi}
                id="clinicProvinsi"
                name="clinicProvinsi"
                onChange={setPayload}
                placeholder="Provinsi Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicKabupatenKota">Kabupaten Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicKabupatenKota}
                id="clinicKabupatenKota"
                name="clinicKabupatenKota"
                onChange={setPayload}
                placeholder="Kabupaten Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicKecamatan">Kecamatan Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicKecamatan}
                id="clinicKecamatan"
                name="clinicKecamatan"
                onChange={setPayload}
                placeholder="Kecamatan Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicKelurahan">Kelurahan Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicKelurahan}
                id="clinicKelurahan"
                name="clinicKelurahan"
                onChange={setPayload}
                placeholder="Kelurahan Clinic"
              />
            </div>

            <div className="mt-4 w-full lg:w-4/12 flex flex-col">
              <label htmlFor="clinicKodePos">Kodepos Clinic:</label>
              <Input
                className="w-full"
                value={payload.clinicKodePos}
                id="clinicKodePos"
                name="clinicKodePos"
                onChange={setPayload}
                placeholder="Kodepos Clinic"
              />
            </div>
          </>
        )}

        <Button state="primary" className="px-6 mt-4" onClick={fnSubmit}>
          <button>Simpan</button>
        </Button>
      </section>
    </div>
  );
}
