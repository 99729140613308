import React from "react";
import { ReactComponent as Logo } from "assets/images/logo-horizontal.svg";
import { Link } from "react-router-dom";

export default function Sitemap() {
  return (
    <footer className="flex flex-col lg:flex-row justify-between py-8 text-neutral-600">
      <ul className="flex flex-col lg:flex-row items-center -mx-4 ">
        <li className="px-4 ">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`}
            className="hover:text-neutral-700 hover:underline"
          >
            Terms and service
          </a>
        </li>
        <li className="px-4 ">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy`}
            className="hover:text-neutral-700 hover:underline"
          >
            Privacy and Policy
          </a>
        </li>
        <li className="px-4 ">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/about`}
            className="hover:text-neutral-700 hover:underline"
          >
            About
          </a>
        </li>
      </ul>
      <div className="flex flex-col mt-6 lg:mt-0 lg:flex-row text-center lg:text-center items-center">
        <p className="text-sm mr-5">
          Copyrights{" "}
          {new Date(process.env.REACT_APP_SITE_START_TIMESTAMP).getFullYear()} •
          All Rights Reserved Mejadokter.com
        </p>
        <Link to="/">
          <Logo className="logo w-32 mx-auto" />
        </Link>
      </div>
    </footer>
  );
}
