import { TOGGLE_SIDEBAR } from "../types/components";

const initialState = {
  sidebar: {
    isVisible: false,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          isVisible:
            action.payload === undefined
              ? !state.sidebar.isVisible
              : action.payload,
        },
      };

    default:
      return state;
  }
}

export default reducer;
