import { LOGIN, LOGOUT } from "../types/user";

const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT:
      return null;

    default:
      return state;
  }
}

export default reducer;
