const configs = {
  size: {
    sm: {
      track: "h-4 w-8",
      knob: "w-4 h-4",
    },
    md: {
      track: "h-5 w-10",
      knob: "w-5 h-5",
    },
    lg: {
      track: "h-7 w-14",
      knob: "w-7 h-7",
    },
  },
};

export default configs;
