const configs = {
   size: {
      sm: {
         wrapper: 'w-6 h-6 text-sm',
         indicator: 'w-2 h-2 border',
      },
      md: {
         wrapper: 'w-8 h-8 text-base',
         indicator: 'w-3 h-3 border',
      },
      lg: {
         wrapper: 'w-10 h-10 text-lg',
         indicator: 'w-4 h-4 border',
      },
   },
   state: {
      online: 'bg-green-500 border-white',
      offline: 'bg-neutral-500 border-white',
      idle: 'bg-blue-300 border-white',
      default: '',
   },
}

export default configs
