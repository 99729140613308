import { useState, useCallback } from "react";

export default function useForm(initialState) {
  const [state, setstate] = useState(initialState);

  const fnUpdateState = useCallback((event) => {
    event.persist && event.persist();

    setstate((prev) => ({
      ...prev,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  }, []);

  const fnUpdateData = useCallback((payload) => {
    setstate((prev) => ({
      ...prev,
      ...payload,
    }));
  }, []);

  return [state, fnUpdateState, fnUpdateData];
}
