const override = (target, source, current = {}) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && target[key] instanceof Object)
      Object.assign(source[key] || {}, override(target[key], source[key]));
  }

  Object.assign(current, target || {}, source);
  return current;
};

export default override;
