import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import Icon from "components/Icon";
import Input from "components/Form/Input";
import Button from "components/Button/";
import Dropdown from "components/Dropdown";

import useForm from "helpers/hooks/useForm";

import { createOne, getOne, updateOne } from "configs/api/users";
import { getAll } from "configs/api/vendors";
export default function Form() {
  const { userId } = useParams();
  const history = useHistory();

  const [payload, setPayload, updatePayload] = useForm({
    name: "",
    email: "",
    password: "",
    vendorId: "",
  });

  const [vendors, setVendors] = useState([]);

  async function fnSubmit() {
    try {
      if (userId) {
        const res = await updateOne(userId, {
          ...payload,
          password: payload.password !== "" ? payload.password : undefined,
        });
        if (res) {
          history.push("/users");
        }
      } else {
        const res = await createOne({
          ...payload,
          password: payload.password !== "" ? payload.password : undefined,
        });
        if (res) {
          history.push("/users");
        }
      }
    } catch (error) {}
  }

  const getUser = useCallback(async () => {
    try {
      if (userId) {
        const res = await getOne(userId);
        const { name, email, vendorId } = res.data.payload;

        updatePayload({
          name,
          email,
          vendorId,
        });
      }
    } catch (error) {}
  }, [userId, updatePayload]);

  const getVendors = useCallback(async () => {
    try {
      const res = await getAll();

      setVendors(res.data.payload);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getUser();
    getVendors();
  }, [getUser, getVendors]);

  return (
    <div className="mb-12">
      <section id="items" className="px-12 pt-12">
        <div className="flex flex-wrap px-4">
          <div className="-mx-4">
            <h3 className="text-xl flex items-center">
              <Link to={`/users`} className="">
                <Icon size="22" item="arrow-back-outline" />
              </Link>
              <span className="ml-2">
                {userId ? "Ubah User" : "Tambah User"}{" "}
              </span>
            </h3>
          </div>
        </div>
        <div className="mt-4 w-4/12 flex flex-col">
          <label htmlFor="name">Select Vendor:</label>
          <Dropdown
            className="px-4"
            configs={{
              list: {
                closeOnSelect: true,
                default: "Vendor",
                empty: "No option available",
              },
            }}
            list={vendors.map((vendor) => {
              return {
                display: <span>{vendor.name}</span>,
                id: vendor.id,
                name: vendor.name,
                onSelect: () => {
                  setPayload({
                    target: {
                      name: "vendorId",
                      value: String(vendor.id),
                    },
                  });
                },
                value: String(vendor.id),
              };
            })}
            value={String(payload.vendorId)}
            name="vendorId"
          />
        </div>
        {payload.vendorId && (
          <>
            <div className="mt-4 w-4/12 flex flex-col">
              <label htmlFor="name">Nama Sales:</label>
              <Input
                className="w-full"
                value={payload.name}
                id="name"
                name="name"
                onChange={setPayload}
                placeholder="Nama User"
              />
            </div>
            <div className="mt-4 w-4/12 flex flex-col">
              <label htmlFor="name">Email:</label>
              <Input
                type="email"
                className="w-full"
                value={payload.email}
                id="email"
                name="email"
                onChange={setPayload}
                placeholder="Email"
              />
            </div>
            <div className="mt-4 w-4/12 flex flex-col">
              <label htmlFor="name">Password:</label>
              <Input
                type="password"
                className="w-full"
                value={payload.password}
                id="password"
                name="password"
                onChange={setPayload}
                placeholder="Password"
              />
            </div>
          </>
        )}

        <Button state="primary" className="px-6 mt-4" onClick={fnSubmit}>
          <button>Simpan</button>
        </Button>
      </section>
    </div>
  );
}
