import React from "react";

import Sidebar from "./Sidebar";
import Footer from "parts/Footer";
import Icon from "components/Icon";
import Button from "components/Button";

import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "redux/actions/components";
export default function Main({ children }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <div className="bg-slate-100 min-h-screen">
      <div className="container mx-auto lg:min-h-screen px-4 lg:px-0">
        <header className="flex lg:hidden pt-4 items-center">
          <Button
            state="primary"
            className="px-3 rounded-full"
            onClick={() => dispatch(toggleSidebar())}
          >
            <button>
              <Icon size="16" item="menu-outline"></Icon>
            </button>
          </Button>
          <h6 className="ml-4">{user?.email ?? "-"}</h6>
        </header>
        <main className="flex pt-4 lg:pt-10">
          <Sidebar />
          <div className="relative z-20 w-full flex-1 rounded-xl lg:rounded-tr-xl rounded-br-xl bg-white">
            {children}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
