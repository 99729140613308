import axios from "configs/axios";

export const login = (data = {}, headers = {}) =>
  axios({
    url: `/auth/email`,
    method: "POST",
    headers,
    data,
  });

// export const refresh = (body = {}, headers = {}) =>
//   axios({
//     url: `/api/v2/auth/refresh-token`,
//     method: "POST",
//     headers,
//     data: {
//       refreshToken: body,
//     },
//   });
