const configs = {
  state: {
    default: {
      field:
        "border border-neutral-400 focus:border-neutral-600 text-neutral-1000",
      icon: "text-neutral-1000",
    },
    info: {
      field: "border border-blue-500 focus:border-blue-400 text-neutral-1000",
      icon: "text-blue-400",
    },
    success: {
      field: "border border-green-500 focus:border-green-400 text-neutral-1000",
      icon: "text-green-400",
    },
    warning: {
      field: "border border-yellow-500 focus:border-yellow-400 ",
      icon: "text-yellow-500",
    },
    error: {
      field: "border border-red-500 focus:border-red-400 text-neutral-1000",
      icon: "text-red-500",
    },
    disabled: {
      field:
        "select-none relative border disabled:border-neutral-400 disabled:bg-neutral-300 disabled:text-neutral-600",
      icon: "text-neutral-600",
    },
    readOnly: {
      field:
        "select-none relative border border-neutral-400 bg-neutral-300 text-neutral-600",
      icon: "text-neutral-600",
    },
    custom: {
      field: "",
      icon: "",
    },
  },
  size: {
    sm: "text-xs h-8",
    md: "text-sm h-10",
    lg: "text-base h-12",
  },
};

export default configs;
