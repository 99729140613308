import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function GuestRoute(props) {
  const user = useSelector((state) => state.user);

  if (user) return <Redirect to={localStorage.rel || "/"} />;

  return <Route {...props} />;
}
