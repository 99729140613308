import React, { useEffect, useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Button from "components/Button/";
import Icon from "components/Icon";

import Filter from "./Filter";
import Table from "./Table";

import useAsync from "helpers/hooks/useAsync";
import { getAll } from "configs/api/leads";
import { useSelector } from "react-redux";

export default function List() {
  // const [filter, setFilter] = useState("");
  const [term, setTerm] = useState("");
  const user = useSelector((state) => state.user);

  const { salesId } = useParams();
  const { data, run, isLoading, isIdle } = useAsync();

  const getData = useCallback(() => {
    run(getAll());
  }, [run]);

  useEffect(() => {
    getData();
  }, [getData]);

  const sales = salesId ? `/sales/${salesId}` : "";

  return (
    <div className="mb-12">
      <section id="items" className="px-4 pt-4 lg:px-12 lg:pt-12">
        <div className="flex justify-between items-center flex-wrap -mx-4">
          <div className="px-4 w-auto">
            <h3 className="text-xl">Leads</h3>
          </div>
          {!user.admin && (
            <div className="px-4 w-auto">
              <Button state="primary" className="px-6">
                <Link to={`/leads/add${sales}`}>
                  <Icon size="16" item="edit-outline" />
                  <span className="ml-2">Create New</span>
                </Link>
              </Button>
            </div>
          )}
        </div>
      </section>

      <Filter
        // filter={filter}
        // setFilter={setFilter}
        term={term}
        setTerm={setTerm}
      />

      <Table
        term={term}
        isLoading={isLoading}
        isIdle={isIdle}
        data={data}
        getData={getData}
      />
    </div>
  );
}
