import React from "react";
import propTypes from "prop-types";

import * as eva from "eva-icons";

const collections = [
  "activity",
  "activity-outline",
  "alert-circle",
  "alert-circle-outline",
  "alert-triangle",
  "alert-triangle-outline",
  "archive",
  "archive-outline",
  "arrow-circle-down",
  "arrow-circle-down-outline",
  "arrow-circle-up",
  "arrow-circle-up-outline",
  "arrow-circle-left",
  "arrow-circle-left-outline",
  "arrow-circle-right",
  "arrow-circle-right-outline",
  "arrow-down",
  "arrow-down-outline",
  "arrow-up",
  "arrow-up-outline",
  "arrow-left",
  "arrow-left-outline",
  "arrow-right",
  "arrow-right-outline",
  "arrow-downward",
  "arrow-downward-outline",
  "arrow-upward",
  "arrow-upward-outline",
  "arrow-back",
  "arrow-back-outline",
  "arrow-forward",
  "arrow-forward-outline",
  "arrow-ios-downward",
  "arrow-ios-downward-outline",
  "arrow-ios-upward",
  "arrow-ios-upward-outline",
  "arrow-ios-back",
  "arrow-ios-back-outline",
  "arrow-ios-forward",
  "arrow-ios-forward-outline",
  "arrowhead-down",
  "arrowhead-down-outline",
  "arrowhead-up",
  "arrowhead-up-outline",
  "arrowhead-left",
  "arrowhead-left-outline",
  "arrowhead-right",
  "arrowhead-right-outline",
  "at",
  "at-outline",
  "attach-2",
  "attach-2-outline",
  "attach",
  "attach-outline",
  "award",
  "award-outline",
  "backspace",
  "backspace-outline",
  "bar-chart-2",
  "bar-chart-2-outline",
  "bar-chart",
  "bar-chart-outline",
  "battery",
  "battery-outline",
  "behance",
  "behance-outline",
  "bell-off",
  "bell-off-outline",
  "bell",
  "bell-outline",
  "bluetooth",
  "bluetooth-outline",
  "book-open",
  "book-open-outline",
  "book",
  "book-outline",
  "bookmark",
  "bookmark-outline",
  "briefcase",
  "briefcase-outline",
  "browser",
  "browser-outline",
  "brush",
  "brush-outline",
  "bulb",
  "bulb-outline",
  "calendar",
  "calendar-outline",
  "camera",
  "camera-outline",
  "car",
  "car-outline",
  "cast",
  "cast-outline",
  "charging",
  "charging-outline",
  "checkmark-circle-2",
  "checkmark-circle-2-outline",
  "checkmark-circle",
  "checkmark-circle-outline",
  "checkmark",
  "checkmark-outline",
  "checkmark-square-2",
  "checkmark-square-2-outline",
  "checkmark-square",
  "checkmark-square-outline",
  "chevron-down",
  "chevron-down-outline",
  "chevron-up",
  "chevron-up-outline",
  "chevron-left",
  "chevron-left-outline",
  "chevron-right",
  "chevron-right-outline",
  "clipboard",
  "clipboard-outline",
  "clock",
  "clock-outline",
  "close-circle",
  "close-circle-outline",
  "close-outline",
  "close-outline-outline",
  "close-square",
  "close-square-outline",
  "cloud-download",
  "cloud-download-outline",
  "cloud-upload",
  "cloud-upload-outline",
  "code-download",
  "code-download-outline",
  "code",
  "code-outline",
  "collapse",
  "collapse-outline",
  "color-pallete",
  "color-pallete-outline",
  "color-picker",
  "color-picker-outline",
  "compass",
  "compass-outline",
  "copy",
  "copy-outline",
  "corner-down-left",
  "corner-down-left-outline",
  "corner-down-right",
  "corner-down-right-outline",
  "corner-left-down",
  "corner-left-down-outline",
  "corner-left-up",
  "corner-left-up-outline",
  "corner-right-down",
  "corner-right-down-outline",
  "corner-right-up",
  "corner-right-up-outline",
  "corner-up-left",
  "corner-up-left-outline",
  "corner-up-right",
  "corner-up-right-outline",
  "credit-card",
  "credit-card-outline",
  "crop",
  "crop-outline",
  "cube",
  "cube-outline",
  "diagonal-arrow-left-down",
  "diagonal-arrow-left-down-outline",
  "diagonal-arrow-left-up",
  "diagonal-arrow-left-up-outline",
  "diagonal-arrow-right-down",
  "diagonal-arrow-right-down-outline",
  "diagonal-arrow-right-up",
  "diagonal-arrow-right-up-outline",
  "done-all",
  "done-all-outline",
  "download",
  "download-outline",
  "droplet-off",
  "droplet-off-outline",
  "droplet",
  "droplet-outline",
  "edit-2",
  "edit-2-outline",
  "edit",
  "edit-outline",
  "email",
  "email-outline",
  "expand",
  "expand-outline",
  "external-link",
  "external-link-outline",
  "eye-off-2",
  "eye-off-2-outline",
  "eye-off",
  "eye-off-outline",
  "eye",
  "eye-outline",
  "facebook",
  "facebook-outline",
  "file-add",
  "file-add-outline",
  "file",
  "file-outline",
  "file-remove",
  "file-remove-outline",
  "file-text",
  "file-text-outline",
  "film",
  "film-outline",
  "flag",
  "flag-outline",
  "flash-off",
  "flash-off-outline",
  "flash",
  "flash-outline",
  "flip",
  "flip-outline",
  "flip-2",
  "flip-2-outline",
  "folder-add",
  "folder-add-outline",
  "folder",
  "folder-outline",
  "folder-remove",
  "folder-remove-outline",
  "funnel",
  "funnel-outline",
  "gift",
  "gift-outline",
  "github",
  "github-outline",
  "globe-2",
  "globe-2-outline",
  "globe",
  "globe-outline",
  "google",
  "google-outline",
  "grid",
  "grid-outline",
  "hard-drive",
  "hard-drive-outline",
  "hash",
  "hash-outline",
  "headphones",
  "headphones-outline",
  "heart",
  "heart-outline",
  "home",
  "home-outline",
  "image",
  "image-outline",
  "inbox",
  "inbox-outline",
  "info",
  "info-outline",
  "keypad",
  "keypad-outline",
  "layers",
  "layers-outline",
  "layout",
  "layout-outline",
  "link-2",
  "link-2-outline",
  "link",
  "link-outline",
  "linkedin",
  "linkedin-outline",
  "list",
  "list-outline",
  "loader",
  "loader-outline",
  "lock",
  "lock-outline",
  "log-in",
  "log-in-outline",
  "log-out",
  "log-out-outline",
  "map",
  "map-outline",
  "maximize",
  "maximize-outline",
  "menu-2",
  "menu-2-outline",
  "menu-arrow",
  "menu-arrow-outline",
  "menu",
  "menu-outline",
  "message-circle",
  "message-circle-outline",
  "message-square",
  "message-square-outline",
  "mic-off",
  "mic-off-outline",
  "mic",
  "mic-outline",
  "minimize",
  "minimize-outline",
  "minus-circle",
  "minus-circle-outline",
  "minus",
  "minus-outline",
  "minus-square",
  "minus-square-outline",
  "monitor",
  "monitor-outline",
  "moon",
  "moon-outline",
  "more-horizontal",
  "more-horizontal-outline",
  "more-vertical",
  "more-vertical-outline",
  "move",
  "move-outline",
  "music",
  "music-outline",
  "navigation-2",
  "navigation-2-outline",
  "navigation",
  "navigation-outline",
  "npm",
  "npm-outline",
  "options-2",
  "options-2-outline",
  "options",
  "options-outline",
  "pantone",
  "pantone-outline",
  "paper-plane",
  "paper-plane-outline",
  "pause-circle",
  "pause-circle-outline",
  "people",
  "people-outline",
  "percent",
  "percent-outline",
  "person-add",
  "person-add-outline",
  "person-delete",
  "person-delete-outline",
  "person-done",
  "person-done-outline",
  "person",
  "person-outline",
  "person-remove",
  "person-remove-outline",
  "phone-call",
  "phone-call-outline",
  "phone-missed",
  "phone-missed-outline",
  "phone-off",
  "phone-off-outline",
  "phone",
  "phone-outline",
  "pie-chart",
  "pie-chart-outline",
  "pin",
  "pin-outline",
  "play-circle",
  "play-circle-outline",
  "plus-circle",
  "plus-circle-outline",
  "plus",
  "plus-outline",
  "plus-square",
  "plus-square-outline",
  "power",
  "power-outline",
  "pricetags",
  "pricetags-outline",
  "printer",
  "printer-outline",
  "question-mark-circle",
  "question-mark-circle-outline",
  "question-mark",
  "question-mark-outline",
  "radio-button-off",
  "radio-button-off-outline",
  "radio-button-on",
  "radio-button-on-outline",
  "radio",
  "radio-outline",
  "recording",
  "recording-outline",
  "refresh",
  "refresh-outline",
  "repeat",
  "repeat-outline",
  "rewind-left",
  "rewind-left-outline",
  "rewind-right",
  "rewind-right-outline",
  "save-outline",
  "save-outline-outline",
  "scissors",
  "scissors-outline",
  "search",
  "search-outline",
  "settings-2",
  "settings-2-outline",
  "settings",
  "settings-outline",
  "share",
  "share-outline",
  "shake",
  "shake-outline",
  "shield-off",
  "shield-off-outline",
  "shield",
  "shield-outline",
  "shopping-bag",
  "shopping-bag-outline",
  "shopping-cart",
  "shopping-cart-outline",
  "shuffle-2",
  "shuffle-2-outline",
  "shuffle",
  "shuffle-outline",
  "skip-back",
  "skip-back-outline",
  "skip-forward",
  "skip-forward-outline",
  "slash",
  "slash-outline",
  "smartphone",
  "smartphone-outline",
  "smiling-face",
  "smiling-face-outline",
  "speaker",
  "speaker-outline",
  "square",
  "square-outline",
  "star",
  "star-outline",
  "stop-circle",
  "stop-circle-outline",
  "sun",
  "sun-outline",
  "swap",
  "swap-outline",
  "sync",
  "sync-outline",
  "text",
  "text-outline",
  "thermometer-minus",
  "thermometer-minus-outline",
  "thermometer",
  "thermometer-outline",
  "thermometer-plus",
  "thermometer-plus-outline",
  "toggle-left",
  "toggle-left-outline",
  "toggle-right",
  "toggle-right-outline",
  "trash-2",
  "trash-2-outline",
  "trash",
  "trash-outline",
  "trending-down",
  "trending-down-outline",
  "trending-up",
  "trending-up-outline",
  "tv-outline",
  "tv-outline-outline",
  "twitter",
  "twitter-outline",
  "umbrella",
  "umbrella-outline",
  "undo",
  "undo-outline",
  "unlock",
  "unlock-outline",
  "upload",
  "upload-outline",
  "video-off",
  "video-off-outline",
  "video",
  "video-outline",
  "volume-down",
  "volume-down-outline",
  "volume-mute",
  "volume-mute-outline",
  "volume-off",
  "volume-off-outline",
  "volume-up",
  "volume-up-outline",
  "wifi-off",
  "wifi-off-outline",
  "wifi",
  "wifi-outline",
];

/**
 * Awesome icon collection from [**eva-icon**](https://akveo.github.io/eva-icons/#/)
 */
const Icon = ({
  item = "",
  height = 0,
  width = 0,
  size = 0,
  wrapperClassName = "",
  className = "",
}) => {
  const icon = collections.includes(item) ? item : "slash-outine";

  return (
    <span
      className={wrapperClassName}
      dangerouslySetInnerHTML={{
        __html: `<svg xmlns="http://www.w3.org/2000/svg" width="${
          Number(size) || Number(width)
        }" height="${
          Number(size) || Number(height)
        }" viewBox="0 0 24 24" class="fill-current ${className}">${eva.icons[
          icon
        ].toString()}</svg>`,
      }}
    ></span>
  );
};

Icon.defaultProps = {
  item: "github",
};

Icon.propTypes = {
  /**
   * ☢ Pick icon from [**eva-icon**](https://akveo.github.io/eva-icons/#/)
   */
  item: propTypes.oneOf(collections),

  /**
   * 🎚 Define size of icon
   */
  size: propTypes.oneOfType([propTypes.number, propTypes.string]),
};

export default Icon;
