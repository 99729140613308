/*eslint eqeqeq: "off"*/
/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
String.prototype.date = function (date = new Date()) {
  let monthNames = {
    id: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  date = new Date(date);
  // if (process.env.REACT_APP_HOST === 'https://app.mejadokter.com') date = new Date(date + 'UTC');

  if (date == "Invalid Date") return "-";

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();

  return day + " " + monthNames.id[monthIndex] + " " + year;
};
