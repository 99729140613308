import React from "react";

import Layout from "parts/Layout";
import Documents from "parts/Documents";
import { useSelector } from "react-redux";

export default function Dashboard() {
  const user = useSelector((state) => state.user);

  return (
    <Documents>
      <Layout>
        <div className="mb-12">
          <section id="items" className="px-4 pt-4 lg:px-12 lg:pt-12">
            <div className="flex flex-wrap px-4">
              <div className="-mx-4">
                <h3 className="text-xl">Welcome {user?.user?.name}</h3>
                <span>{user?.user?.vendor?.name ?? ""}</span>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </Documents>
  );
}
