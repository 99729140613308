import React from "react";
import { useHistory } from "react-router-dom";

import Footer from "parts/Footer";
import Documents from "parts/Documents";

import Controller, { Label } from "components/Form/";
import Button from "components/Button/";
import Input, { color } from "components/Form/Input";
import Group from "@goalabs.id/react-shared-components/dist/components/Group/";
import Icon from "components/Icon";

import { setAuthorizationHeader } from "configs/axios";
import { login } from "configs/api/oAuth";
import { setLogin } from "redux/actions/user";
import { useDispatch } from "react-redux";

import jwt_decode from "jwt-decode";

export default function Login() {
  const history = useHistory();
  const [iCanSeeYou, setiCanSeeYou] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const payload = {
    email: "",
    password: "",
  };

  function onSubmit(payload) {
    setLoading(true);
    login({ ...payload })
      .then(({ data }) => {
        setLoading(false);
        const save = {
          token: data.payload,
          email: payload.email,
        };

        const session = jwt_decode(data.payload);

        dispatch(setLogin(session));

        localStorage.setItem("user", JSON.stringify(save));

        setAuthorizationHeader(data.payload);
        history.push("/");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  function onError(element) {
    // console.dir(element)
  }

  return (
    <Documents>
      <section className="lg:min-h-screen flex flex-col mt-12 lg:mt-0 lg:items-center justify-center px-4">
        <div className="w-full lg:w-96">
          <h1 className="text-2xl mb-4">Login • CRM</h1>
          <div className="border border-gray-100">
            <Controller
              onSubmit={(payload) => onSubmit(payload)}
              onError={(element) => onError(element)}
              data={payload}
              options={{
                Input: {
                  default: {},
                  error: {
                    state: "error",
                  },
                },
              }}
            >
              <div
                style={{
                  padding: 30,
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ccc",
                  borderRadius: 6,
                }}
              >
                <div className="flex flex-col mb-4">
                  <Label htmlFor="name">Username:</Label>
                  <Input
                    state="default"
                    id="email"
                    name="email"
                    required
                    defaultValue={payload.email}
                  />
                </div>

                <div className="flex flex-col mb-4">
                  <Label htmlFor="password">Password:</Label>
                  <div className="relative w-full">
                    <Input
                      state="default"
                      className="w-full"
                      id="password"
                      name="password"
                      type={iCanSeeYou ? "text" : "password"}
                      required
                      defaultValue={payload.password}
                    />
                    <button
                      type="button"
                      onClick={() => setiCanSeeYou((prev) => !prev)}
                      className={[
                        "absolute right-0 top-0 w-10 h-full flex items-center justify-center focus:outline-none",
                        color("disabled"),
                      ].join(" ")}
                    >
                      <Icon
                        size="16"
                        item="eye-off-outline"
                        className={iCanSeeYou ? "" : "hidden"}
                      />
                      <Icon
                        size="16"
                        item="eye-outline"
                        className={iCanSeeYou ? "hidden" : ""}
                      />
                    </button>
                  </div>
                </div>

                <Group
                  className="evenly-space"
                  style={{ marginTop: 32, alignItems: "center" }}
                >
                  <Button loading={isLoading} state="primary" className="px-6">
                    <button className="">Login</button>
                  </Button>
                </Group>
              </div>
            </Controller>
          </div>
        </div>
        <div className="lg:absolute bottom-0 left-0 right-0 px-12">
          <Footer />
        </div>
      </section>
    </Documents>
  );
}
