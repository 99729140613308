import React from "react";
import { Switch, Route } from "react-router-dom";

import Layout from "parts/Layout";
import Documents from "parts/Documents";

import List from "./List";
import Form from "./Form";

export default function SalesVendor() {
  return (
    <Documents>
      <Layout>
        <Switch>
          <Route exact path="/leads" component={List} />
          <Route path="/leads/list" component={List} />
          <Route path="/leads/add" component={Form} />
          <Route path="/leads/:leadsId" component={Form} />
        </Switch>
      </Layout>
    </Documents>
  );
}
