import React, { useCallback, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import Icon from "components/Icon";
import Input from "components/Form/Input";
import Button from "components/Button/";

import useForm from "helpers/hooks/useForm";

import { createOne, getOne, updateOne } from "configs/api/vendors";
export default function Form() {
  const { id } = useParams();
  const history = useHistory();

  const [payload, setPayload, updatePayload] = useForm({
    name: "",
  });

  async function fnSubmit() {
    try {
      if (id) {
        const res = await updateOne(id, { name: payload.name });
        if (res) {
          history.push("/vendors");
        }
      } else {
        const res = await createOne({ name: payload.name });
        if (res) {
          history.push("/vendors");
        }
      }
    } catch (error) {}
  }

  const getVendor = useCallback(async () => {
    try {
      const res = await getOne(id);
      const { name } = res.data.payload;

      updatePayload({ name });
    } catch (error) {}
  }, [id, updatePayload]);

  useEffect(() => {
    getVendor();
  }, [getVendor]);

  return (
    <div className="mb-12">
      <section id="items" className="px-12 pt-12">
        <div className="flex flex-wrap px-4">
          <div className="-mx-4">
            <h3 className="text-xl flex items-center">
              <Link to={`/vendors`} className="">
                <Icon size="22" item="arrow-back-outline" />
              </Link>
              <span className="ml-2">
                {id ? "Ubah Sales Vendor" : "Tambah Sales Vendor Baru"}{" "}
              </span>
            </h3>
          </div>
        </div>
        <div className="mt-4 w-4/12 flex">
          <Input
            className="w-full"
            value={payload.name}
            id="name"
            name="name"
            onChange={setPayload}
            placeholder="Nama Vendor"
          />
        </div>

        <Button state="primary" className="px-6 mt-4" onClick={fnSubmit}>
          <button>Simpan</button>
        </Button>
      </section>
    </div>
  );
}
