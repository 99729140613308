import React from "react";
import propTypes from "prop-types";

import Group from "@goalabs.id/react-shared-components/dist/components/Group";

import "@goalabs.id/react-shared-components/dist/components/Group/index.css";

const type = ["normal", "adjacent", "evenly-space"];

/**
 * A component utilities to wrap children as a group
 */
const GroupComps = (props) => {
  return <Group {...props}>{props.children}</Group>;
};

GroupComps.defaultProps = {
  type: "normal",
};

GroupComps.propTypes = {
  /**
   * type of group
   */
  type: propTypes.oneOf(type),
};

export default GroupComps;
