const sidebar = {
  dashboard: {
    to: "/",
    alias: "/dashboard",
    text: "Dashboard",
    icon: "home-outline",
    exact: true,
  },

  vendors: {
    to: "/vendors",
    text: "Vendors",
    icon: "person-done-outline",
    // child: {
    //   profile: {
    //     to: "/pricing/profile",
    //     text: "Profile",
    //   },
    //   billing: {
    //     to: "/pricing/billing",
    //     text: "Billing",
    //   },
    // },
  },

  users: {
    to: "/users",
    text: "Users",
    icon: "person-outline",
  },

  leads: {
    to: "/leads",
    text: "Leads",
    icon: "at-outline",
  },

  // "sales-leads": {
  //   to: "/sales-leads",
  //   text: "Sales Leads",
  //   icon: "credit-card-outline",
  // },
};

export default sidebar;
